<template>
  <div class="mt-4 overflow-auto " :style="`height:` + cardHeight + `px;max-height:` + cardHeight + `px;overflow: auto; !important`">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full relative">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">{{parseInt(this.$route.params.expId) > 0 ? 'Edit Expense' : 'Add Expense'}}</h1>
      </div>
      <div v-if="parseInt(this.$route.params.expId) > 0" class=" text-error heading-4 absolute right-3 top-1.5 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="deleteReqConfirm = true">
        <i class="fa-solid fa-trash"></i>
      </div>
    </div>
    <div class="flex justify-center">
      <div class=" sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class=" card rounded-xl bg-white p-4 my-4">
          <div class="sm:grid grid-cols-2 gap-4 mt-2">
            <div class="mb-4">
              <TextField
              :inputId="'itemName'"
              :textMaxlength="50"
              :showcharLimit="true"
              :inputext="expItemName"
              :placholderText="`Title`"
              :lableText="'Title'"
              :autoFocus="false"
              :fieldError="errObj.itemNameErr !== ''"
              @keyPressAction="errObj.itemNameErr = ''"
              @inputChangeAction="(data) => expItemName = data"  
              />
              <p v-if="errObj.itemNameErr !== ''" class="text-error heading-7">{{errObj.itemNameErr}}</p>
            </div>
            <div class="mb-4">
              <div class="mb-4 sm:mb-0">
                    <TextField :isPointer="true" :placholderText="`Date`" :setReadOnly="true" :autoFocus="false" :inputext="sendDate" :showDate="true" :lableText="'Date'" :inputId="'Invoicedkid'" @onClickInputAction="invoiceDatePickShow = true" />
                    <div v-if="invoiceDatePickShow" class="popup_overlay">
                        <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                            <DateTimePickerCustom
                                v-model="sendDate"
                                v-if="invoiceDatePickShow"
                                :showDate="true"
                                :title="'Date'"
                                :showOnlyDate="true"
                                :showStartDate="true"
                                :projectSection="true"
                                @input="updateDate()"
                                @closeDateTimepickerDialog="invoiceDatePickShow = false">
                            </DateTimePickerCustom>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div>
                <div class="heading-5" v-if="!addExpDesc">
                  <span @click="addExpDesc = true" class="text-primary font-bold cursor-pointer">Add Description</span>
                </div>
                  <TextAreaInput v-if="addExpDesc"
                  :inputId="'invicenoteid1'"
                  :inputext="expDetail"
                  :placholderText="`Write Description here...`"
                  :lableText="'Description'"
                  :inputType="'text'"
                  :autoFocus="false"
                  :textMaxlength="1000"
                  :showcharLimit="true"
                  :cols="50"
                  :rows="3"
                  :setReadOnly="false"
                  @inputChangeAction="(data) => expDetail = data" 
                  />
              </div>
          <div class="sm:grid grid-cols-2 gap-4 mt-2">
            <div class="mb-4">
              <TextField
               :inputId="`itemprice1`"
               :inputext="expTotal"
               :placholderText="`Total`"
               :lableText="'Total'"
               :inputType="'number'"
               :textRight="true"
               :autoFocus="false"
               :setReadOnly="false"
               :inputTypeText="'number'"
               :fieldError="errObj.itemPriceErr !== ''"
               @keyPressAction="errObj.itemPriceErr = ''"
               @inputChangeAction="(data) => expTotal = data" />
              <p v-if="errObj.itemPriceErr !== ''" class="text-error heading-7">{{errObj.itemPriceErr}}</p>
            </div>
          </div>
          <div class="sm:grid grid-cols-2 gap-4">
            <div class="">
              <Dropdown
              :inputext="selectedEmpname"
              :inputId="'empslectinpuid'"
              :lableText="'Reimburse to'"
              :placholderText="``"
              :autoFocus="false"
              :showArrow="true"
              :setReadOnly="false"
              :searchCallApi="true"
              :showPlus="false"
              :keyName="'fullName'"
              :listId="'userDetailId'"
              :items="employeeList"
              :fieldError="errObj.reimbErr !== ''"
              @keyPressAction="errObj.reimbErr = ''"
              @selectNewForVal="selectEmployee"
              />
              <p v-if="errObj.reimbErr !== ''" class="text-error heading-7">{{errObj.reimbErr}}</p>
            </div>
            <div class="">
              <Dropdown
              :inputext="accountingCodeName"
              :inputId="'Accounting'"
              :lableText="'Expense Category'"
              :placholderText="`Select Expense Category`"
              :autoFocus="false"
              :showArrow="true"
              :setReadOnly="false"
              :searchCallApi="true"
              :showPlus="false"
              :keyName="'codeName'"
              :listId="'codeId'"
              :items="accountingCodeList"
              @inputChangeAction="callsearchCust"
              @selectNewForVal="selectCatValue"
              :fieldError="errObj.accountingCodeErr !== ''"
              @keyPressAction="errObj.accountingCodeErr = ''"
               />
              <p v-if="errObj.accountingCodeErr !== ''" class="text-error heading-7">{{errObj.accountingCodeErr}}</p>
            </div>
          </div>
        </div>
        <div class=" card rounded-xl bg-white p-4 py-3 my-4">
          <div class="heading-5 flex items-center justify-between cursor-pointer" @click="existingJob()" v-if="selectedExisitngObject === null">
            <div>
              <span><i class="fa-solid fa-hammer text-primary"></i></span>
              <span class="text-primary font-bold mt-2 pl-3">Linked job</span>
            </div>
            <div>
              <span class="text-primary font-bold heading-2">+</span>
            </div>
          </div>
          <div v-else>
            <div class="flex items-center relative justify-between">
              <div class="text-sm items-center flex">
                <span><i class="fa-solid fa-hammer text-primary heading-5"></i></span>
                <div class="pl-3 heading-3 ">
                  <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                  <div class="flex justify-between items-center">
                    <p class="text-gray4 heading-5">#{{selectedExisitngObject.jobNumber}}</p>
                  </div>
                  <div class="flex justify-between items-center">
                    <p class="text-text1 heading-4 py-1 font-semibold whitespace-pre-line">{{selectedExisitngObject.jobTitle}}</p>
                  </div>
                  <div class="flex justify-between items-center">
                    <p class="text-gray4 heading-5">{{selectedExisitngObject.companyName}}</p>
                  </div>
                </div>
              </div>
              <span class="flex items-center">
                <div class=" text-error heading-4 cursor-pointer mr-3" @click="removeJob()">
                  <i class="fa-solid fa-trash"></i>
                </div>
                <div class=" text-primary heading-4 cursor-pointer" @click="existingJob()">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class=" card rounded-xl bg-white p-4 my-4">
          <div class="flex flex-wrap gap-4 mb-4" v-if="parseInt(this.$route.params.expId) > 0 && attachmentList.length > 0">
            <div class=" flex items-center relative card rounded-xl border-t border-gray-100 bg-gray1 attachment-card group" style="width: 120px; height: 80px"  v-for="(attach, a) in attachmentList " :key="a">
              <div v-if="attach.file.docType === 'image'" class="text-center self-center place-self-center">
                  <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.file.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                  <div class="invisible group-hover:visible absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 mt-3">{{ attach.file.name }}</div>
                </div>
                <div v-if="attach.file.docType === 'gif'"  class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-image"></i></div>
                  <div class="invisible group-hover:visible w-full min-w-max -bottom-9 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.file.name }}</div>
                </div>
                <div v-if="attach.file.docType === 'svg'"  class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-code"></i></div>
                  <div class="invisible group-hover:visible w-full min-w-max -bottom-9 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.file.name }}</div>
                </div>
                <div v-if="attach.file.docType === 'video'"  class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-circle-play"></i></div>
                  <div class="invisible group-hover:visible w-full min-w-max -bottom-9 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.file.name }}</div>
                </div>
                <div v-if="attach.file.docType === 'document'"  class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-excel"></i></div>
                  <div class="invisible group-hover:visible w-full min-w-max -bottom-9 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.file.name }}</div>
                </div>
                <div v-if="attach.file.docType === 'pdf'"  class="flex justify-center items-center w-full">
                  <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                  <div class="invisible group-hover:visible w-full min-w-max -bottom-9 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70">{{ attach.file.name }}</div>
                </div>
              <span @click="removeFileA(a)" class=" absolute top-0 cursor-pointer right-0 bg-white rounded-full flex justify-center heading-3 p-0.5 border-primary"><i class="fa-solid fa-circle-xmark"></i></span>
            </div>  
          </div> 
          <div>
                <div class="col-span-12">
                <input id="files" type="file" ref="file" style="display: none" @change="uploadFiles"/>
                <a
                  @click="$refs.file.click()"
                  class="heading-5 text-primary  font-bold cursor-pointer"
                >
                + Attach Receipt
                </a>
                <div class="heading-6 text-error " v-if="showErr !== ''">{{showErr}}</div>
              </div>
                <div class=" col-span-12" v-if="fileName.length > 0">
                <div>
                  <div class="" v-for="(data, index) in fileName" :key="index" >
                    <p class="text-text1 font-bold" v-if="index === 0">
                      File(s) attachment
                    </p>
                    <div v-if="data.uploadedFile" class=" flex">

                      <div class="flex pr-2">
                        <span class="text-gray3 whitespace-nowrap pr-2">File Name:</span>
                        <div style="display: grid;">
                            <span class="mb-0 mt-0 subtitle-2 text-text2" style="width: 100%; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ data.file.name.split('.')[0]}}</span>
                        </div>
                        <span class="text-text2">.{{data.file.name.split('.')[1]}}</span>
                        </div>

                      <p class="text-text1">
                      
                      <span @click="removeFile(index, data)"><i class="fas fa-minus-circle text-error cursor-pointer" style="margin-top:-3px" ></i></span>
                    </p>
                    </div>
                    <div v-else class="progressDiv">
                      <p class="subtitle-1 font-weight-bold overFlowPara">{{ data.file.name }}</p>
                      <p :id="`progress${data.id}`"></p>
                      <div class="w-full" v-if="data.progressLinearValue">
                        <div class="progress-bar">
                          <div class="progress-bar-value"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
          <div class="flex justify-end  card rounded-xl bg-white p-4 my-4">
            <div class="text-rigth flex gap-2">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'"
                @buttonAction="discard()" />
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'"
                @buttonAction="createNewItem()" />
            </div>
          </div>
        </div>
      </div>
      <div>
    </div>
    <SearchJob v-if="searchJobPopup" />
    <ConfirmBox v-if="deleteReqConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" />
  </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import SearchJob from '@/View/schedule/components/searchJob.vue'
import moment from 'moment'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
// import NumberInput from '@/View/components/number-text-field.vue'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Button,
    ConfirmBox,
    DateTimePickerCustom,
    TextAreaInput,
    // NumberInput,
    TextField,
    SearchJob,
    Dropdown,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      usedStorageVal: 0,
      ImageCount: 0,
      VideoCount: 0,
      showErr: '',
      ImageSizePermission: FilterPermissions('max_image_upload_size'),
      VideoSizePermission: FilterPermissions('max_video_upload_size'),
      canMultipleImagePermission: FilterPermissions('image_limit'),
      canMultipleVideoPermission: FilterPermissions('video_limit'),
      canPlanAssignStorage: FilterPermissions('max_storage'),
      isMultipleSel: false,
      addExpDesc: false,
      deleteReqConfirm: false,
      expenseId: 0,
      files: [],
      pathPrefix: '',
      fileName: [],
      attachmentList: [],
      searchJobPopup: false,
      selectedExisitngObject: null,
      sendDate: '',
      expItemName: '',
      expDetail: '',
      expTotal: 0,
      accountingCodeName: '',
      accountingCodeId: 0,
      deletedFiles: [],
      accountingCodeList: [],
      searchValCode: '',
      selectedEmpname: 'Not Reimbursable',
      reimburseId: 0,
      employeeList: [],
      invoiceDatePickShow: false,
      cardHeight: 0,
      searchForItem: '',
      selectedTaxList: [],
      addNewItemTax: false,
      itemtaxList: [],
      finalTaxList: [],
      addTaxinput: false,
      invObj: {
        itemMasterId: 0,
        itemCategoryMasterId: 0,
        itemCode: '',
        itemName: '',
        itemDescription: '',
        itemPrice: '',
        itemUnit: '',
        isActive: true,
        itemScore: 0,
        categoryName: '',
        itemTaxes: [],
      },
      categoryList: [],
      searchApitext: '',
      errObj: {
        itemNameErr: '',
        itemPriceErr: '',
        accountingCodeErr: '',
        reimbErr: '',
      }
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
    invObj: {
      handler () {
        if (this.invObj.itemName !== '') {
          this.errObj.itemNameErr = ''
        }
        if (this.invObj.itemPrice !== '') {
          this.errObj.itemPriceErr = ''
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getStorageInfo()
    if (!this.canMultipleImagePermission || this.canMultipleImagePermission !== undefined) {
      this.canMultipleImagePermission.v > 1 ? this.isMultipleSel = true : this.isMultipleSel = false
    } else {
      this.isMultipleSel = false
    }
    this.$root.$on('confirmBoxHandler', (response) => {
      if (response) {
        this.deleteApi()
      }
      document.body.style = 'overflow: visible;'
      this.deleteReqConfirm = false
    })
    this.$root.$on("closeScheduleJobSel", (response, isClose) => {
      if (!isClose) {
        this.selectedExisitngObject = response
      }
      this.searchJobPopup = false
    });
    this.getAccountingList()
    this.getEmployeeList()
    this.sendDate = moment(new Date()).format('YYYY-MM-DD')
    if (parseInt(this.$route.params.expId) > 0) {
      this.getItemSDetail ()
    }
  },
  methods: {
    async uploadFiles (event) {
      if (this.canPlanAssignStorage) {
        let mb = parseInt(this.canPlanAssignStorage.v)*1024
        console.log('usedStorageVal', this.usedStorageVal)
        console.log('usedStorageVal mb', mb)
        this.showErr = ''
        if (mb > this.usedStorageVal) {
          console.log('this.note', this.fileName)
          // let Temp = this.fileName.length + event.target.files.length
          this.showErr = ''
          for (let i = 0; i < event.target.files.length; i++) {
            let Isvalid = true
            let file = event.target.files[i]
            let imageSize = this.ImageSizePermission.v === undefined || this.ImageSizePermission.v === 0 ? 1048576 : this.ImageSizePermission.v * 1048576
            let videoSize = this.VideoSizePermission.v === undefined || this.VideoSizePermission.v === 0 ? 5242880 : this.VideoSizePermission.v * 1048576
            console.log('file.type', file.type)
            if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/svg' || file.type === 'application/pdf') {
                if (this.ImageCount < this.canMultipleImagePermission.v) {
                  if (file.size < imageSize) {
                    this.ImageCount += 1
                    Isvalid = true
                    this.files.push(event.target.files[i])
                  } else {
                    Isvalid = false
                    this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size, You have only allowed to upload ${this.convertToMb(imageSize)}MB size image`, color: 'error'})
                    // this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size`, color: 'error'})
                  }
                } else {
                  Isvalid = false
                  this.showErr = `You Don't Have permission to upload more than ${this.canMultipleImagePermission.v} image attachment`
                  this.$store.dispatch('SetAlert', {showAlert: true, message: `You Don't Have permission to upload more than ${this.canMultipleImagePermission.v} image attachment`, color: 'error'})
                }
              } else {
                if (this.VideoCount < this.canMultipleVideoPermission.v) {
                  if (file.size < videoSize) {
                    Isvalid = true
                    this.VideoCount += 1
                    this.files.push(event.target.files[i])
                  } else {
                    Isvalid = false
                    this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size, You have only allowed to upload ${this.convertToMb(videoSize)}MB size video`, color: 'error'})
                    // this.$store.dispatch('SetAlert', {showAlert: true, message: `File: ${file.name} not a valid file size`, color: 'error'})
                  }
                } else {
                  Isvalid = false
                  this.showErr = `You Don't Have permission to upload more than ${this.canMultipleVideoPermission.v} video attachment`
                  this.$store.dispatch('SetAlert', {showAlert: true, message: `You Don't Have permission to upload more than ${this.canMultipleVideoPermission.v} video attachment`, color: 'error'})
                }
              }
              if (Isvalid === true) {
                let newDate = new Date().getTime()
                let obj = {
                  file: file,
                  id: newDate,
                  uploadedFile: true,
                  attachedDocument: false,
                  documentName: '',
                  documentNotes: '',
                  progressLinearValue: 0,
                  source: ''
                }
                this.fileName.push(obj)
              }
            }
            console.log('this.ImageCount', this.ImageCount, this.VideoCount)
          // } else {
          //   this.showErr = `You Don't Have permission to upload more than ${this.canMultipleImagePermission.v} attachment`
          //   this.$store.dispatch('SetAlert', {showAlert: true, message: `You Don't Have permission to upload more than ${this.canMultipleImagePermission.v} attachment`, color: 'error'})
          // }
        } else {
          this.$store.dispatch('SetAlert', {showAlert: true, message: `You have only ${mb} MB storage and you already used total ${this.usedStorageVal} MB, please remove extra attachments`, color: 'error'})
        }
      }
    },
    convertToMb (kb) {
      return kb / (1024 * 1024);
    },
    getStorageInfo () {
      MyJobApp.GetUsedStorageDetail(
          response => {
            console.log('response', response)
            let fileSizeMB = response.Data.usedStorage / (1024 ** 2)
            this.usedStorageVal = fileSizeMB.toFixed(2)
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
      )
    },
    removeFileA (index) {
      let obj = {
      fileName: this.attachmentList[index].file.fileName,
        fileType: this.attachmentList[index].file.fileType,
        thumbPath: this.attachmentList[index].file.thumbPath,
        notesAttachmentMappingId: this.attachmentList[index].file.notesAttachmentMappingId,
        fileVaultId: this.attachmentList[index].file.fileVaultId,
      }
      this.deletedFiles.push(obj)
      this.attachmentList.splice(index, 1)
      const fileListArr = Array.from(this.files)
      fileListArr.splice(index, 1)
      this.files = fileListArr
    },
    removeFile (index, data) {
      this.showErr = ''
      if (data.file.type === 'image/png' || data.file.type === 'image/jpg' || data.file.type === 'image/jpeg' || data.file.type === 'image/gif' || data.file.type === 'image/svg' || data.file.type === 'application/pdf') {
        this.ImageCount --
      } else {
        this.VideoCount --
      }
      console.log('this.fileName[index].file', this.fileName[index])
      if (parseInt(this.$route.params.expId) > 0) {
        let obj = {
          fileName: this.fileName[index].file.fileName,
          fileType: this.fileName[index].file.fileType,
          thumbPath: this.fileName[index].file.thumbPath,
          notesAttachmentMappingId: this.fileName[index].file.notesAttachmentMappingId,
          fileVaultId: this.fileName[index].file.fileVaultId,
        }
        this.deletedFiles.push(obj)
        this.fileName.splice(index, 1)
        const fileListArr = Array.from(this.files)
        fileListArr.splice(index, 1)
        this.files = fileListArr
      } else {
        this.fileName.splice(index, 1)
        const fileListArr = Array.from(this.files)
        fileListArr.splice(index, 1)
        this.files = fileListArr
      }
        console.log('this.deletedFiles' ,this.deletedFiles)
    },
    existingJob () {
      this.searchJobPopup = true
    },
    removeJob () {
      this.selectedExisitngObject = null
    },
    createNewItem() {
      let isValid = true
      if (this.expItemName === '') {
        isValid = false
        this.errObj.itemNameErr = 'Title is Required'
      }
      if (this.expTotal === '') {
        isValid = false
        this.errObj.itemPriceErr = 'Total is Required'
      }
      if (this.reimburseId === 0) {
        isValid = false
        this.errObj.reimbErr = 'Required'
      }
      // if (this.accountingCodeId === 0) {
      //   isValid = false
      //   this.errObj.accountingCodeErr = 'Expense Category is Required'
      // }
      if (isValid) {
        this.expTotal = parseFloat(this.expTotal)
        let jobid = this.selectedExisitngObject !== null ? this.selectedExisitngObject.jobId : 0
        this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
        console.log('this.files', this.files)
        MyJobApp.saveExpenseWeb(
          this.expenseId,
          this.sendDate,
          this.expItemName,
          this.expDetail,
          this.expTotal,
          this.reimburseId,
          jobid,
          this.accountingCodeId,
          this.deletedFiles,
          this.files,
          response => {
            this.$router.push({name: 'ExpenseDetail', params: {expId: response.Data}})
            // this.$router.push({ name: 'SettingItemDetails', params: { itemId: response.Data }})
            this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          },
          (error) => {
            this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          }
        )
      }
    },
    selectCatValue(data) {
      console.log('accountingCodeId', data)
      if (data !== null) {
        this.errObj.accountingCodeErr = ''
        this.accountingCodeName = data.codeName
        this.accountingCodeId = data.codeId
      }
    },
    selectEmployee(data) {
      console.log('accountingCodeId', data)
      this.errObj.reimbErr = ''
      if (data !== null) {
        this.selectedEmpname = data.fullName
        this.reimburseId = data.userDetailId
      } else {
        this.selectedEmpname = ''
        this.reimburseId = 0
      }
    },
    discard() {
      this.$router.go(-1)
    },
    callsearchCust(text) {
      this.searchValCode = text
      this.getAccountingList()
    },
    selectNewTaxes (list) {
      this.selectedTaxList = list
      this.finalTaxList = list
    },
    addNewTax () {
        this.addNewItemTax = true
    },
    getItemSDetail() {
      console.log("call", this.$route);
      this.$store.dispatch("SetLoader", {status: true, message: "Loading...",});
      MyJobApp.getExpenseDetail(
        parseInt(this.$route.params.expId),
        (response) => {
          let Temp = response.Data
          this.expenseId = Temp.expenseId
          this.sendDate = moment(new Date(Temp.expenseDate)).format('YYYY-MM-DD')
          this.expItemName = Temp.itemName
          this.expDetail = Temp.detail
          if (Temp.detail !== '') {
            this.addExpDesc = true
          } else {
            this.addExpDesc = false
          }
          this.expTotal = Temp.totalAmount
          this.accountingCodeName = Temp.accountingCodeName
          this.accountingCodeId = Temp.AccountingCode
          this.selectedEmpname = Temp.reimbersUserName === '' ? 'Not Reimbursable' : Temp.reimbersUserName
          this.reimburseId = Temp.userId
          this.pathPrefix = Temp.pathPrefix
          if (Temp.jobId > 0 ) {
            let obj = {
              jobNumber: Temp.jobNumber,
              jobTitle: Temp.jobTitle,
              companyName: Temp.companyName,
              jobId: Temp.jobId,
            }
            this.selectedExisitngObject = obj
          } else {
            this.selectedExisitngObject = null
          }
          if (response.Data.attachments !== null) {
            let attachmentList = JSON.parse(response.Data.attachments).attachmentList
            console.log('attachmentList', attachmentList)
            let newDate = new Date().getTime()
            if (attachmentList !== null) {
              for (let index = 0; index < attachmentList.length; index++) {
                let file = {
                  name: attachmentList[index].fileName,
                  type: attachmentList[index].fileType,
                  thumbPath: attachmentList[index].thumbPath,
                  notesAttachmentMappingId: attachmentList[index].notesAttachmentMappingId,
                  fileVaultId: attachmentList[index].fileVaultId,
                  lastModified: attachmentList[index].fileName,
                  docType: ''
                }
                if (attachmentList[index].fileType === 'image/png' || attachmentList[index].fileType === 'image/jpg' || attachmentList[index].fileType === 'image/jpeg') {
                    file.docType = 'image'
                  } else if (attachmentList[index].fileType === 'video/mov' || attachmentList[index].fileType === 'video/mvi' || attachmentList[index].fileType === 'video/mp4' || attachmentList[index].fileType === 'video/mkv' || attachmentList[index].fileType === 'video/mpeg' || attachmentList[index].fileType === 'video/avi' || attachmentList[index].fileType === 'video/mpeg4') {
                    file.docType = 'video'
                  } else if (attachmentList[index].fileType === 'application/xls' || attachmentList[index].fileType === 'application/xlsx' || attachmentList[index].fileType === 'application/ods' || attachmentList[index].fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    file.docType = 'document'
                  } else if (attachmentList[index].fileType === 'application/doc' || attachmentList[index].fileType === 'application/docx' || attachmentList[index].fileType === 'application/odt' || attachmentList[index].fileType === 'application/pdf' || attachmentList[index].fileType === 'application/octet-stream') {
                    file.docType = 'pdf'
                  } else if (attachmentList[index].fileType === 'image/svg' || attachmentList[index].fileType === 'image/svg+xml') {
                    file.docType = 'svg'
                  } else if (attachmentList[index].fileType === 'image/gif') {
                    file.docType = 'gif'
                  }
                let obj = {
                  file: file,
                  id: newDate,
                  uploadedFile: true,
                  attachedDocument: false,
                  documentName: '',
                  documentNotes: '',
                  progressLinearValue: 0,
                  source: ''
                }
                this.attachmentList.push(obj)
                // this.fileName.push(obj)
              }
            }
            // this.noteLIst = attachmentList
          } else {
            this.attachmentList = []
          }
          
          // let templist = []


          // let attachmentListA = JSON.parse(Temp.attachments)
          // console.log('attachmentListA', attachmentListA.attachmentList)
          // let newDate = new Date().getTime()
          // for (let index = 0; index < attachmentListA.attachmentList.length; index++) {
          //   let obj = {
          //       file: attachmentListA.attachmentList[index],
          //       id: newDate,
          //       uploadedFile: true,
          //       attachedDocument: false,
          //       documentName: '',
          //       documentNotes: '',
          //       progressLinearValue: 0,
          //       source: ''
          //     }
          //     this.fileName.push(obj)
          // }
          // console.log('this.files', this.files)
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
            temp[index].employeeFirstName = temp[index].firstName 
            temp[index].employeeLastName = temp[index].lastName
            temp[index].fullName = temp[index].firstName + ' ' + temp[index].lastName
          }
          const newFirstElement = {
            userDetailId: 0,
            fullName: "Not Reimbursable",
          }
          this.employeeList = [newFirstElement].concat(temp)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getAccountingList () {
      MyJobApp.getAccountingCodeList(
          0,
          100,
          this.searchValCode,
          'addedDate',
          'DESC',
          response => {
            this.accountingCodeList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    deleteApi () {
      MyJobApp.getExpenseDelete(
          parseInt(this.$route.params.expId),
          response => {
            this.$router.push({ name: 'Expense'})
            this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 80
    },
  },
  beforeDestroy() {
    this.$root.$off("addCustomerHandler");
    this.$root.$off("closeScheduleJobSel");
    this.$root.$off("itemSelectionHandler");
  },
};
</script>
<style scoped></style>