<template>
  <div  class="popup_overlay px-4">
     <div class="custom_dialog rounded-xl" style="width: 800px;max-height: 92%;">
        <div class=" bg-white sticky top-0">
          <div class="bg-primary flex justify-center items-center p-2 text-center">
            <div class="font-medium text-white heading-2">
              Search Job
            </div>
            <div class=" text-primary heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl absolute right-3" @click="closePopup([], true)">
              <i class="fa-solid fa-times"></i>
            </div>
          </div>
        </div>
        <div class="mt-2 p-2 pb-0">
          <div class="flex flex-wrap">
            <div class="flex flex-wrap justify-between">
              <div class="w-72 mb-4">
                <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Job...`"/>
              </div>
              <span class="flex ml-4">
                <div class="" v-for="(status, index) in entStatusList" :key="index" >
                  <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.selected" :btnText="status.displayText" />     
                </div>
              </span>
            </div>
          </div>
        </div>
        <div  style="max-height: 60vh" class="overflow-auto">
          <div style="overflow-y: auto; white-space: nowrap;" v-if="visitLIst.length > 0">
            <div v-for="(data, index) in visitLIst" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-2 py-2 items-center hover:bg-gray1 cursor-pointer" @click="closePopup(data, false)">
              <div class="">
                <p class="text-gray4 heading-6">#{{data.jobNumber}}</p>
                  <p class="text-text1 heading-5 py-1 font-semibold">{{data.jobTitle}}</p>
                  <p class="text-gray4 heading-6">{{data.companyName}}</p>
              </div>
            </div>
          </div>
          <div v-else class="p-4 pt-0">
            <NoRecords :alertMessage="`No records found.`" />
          </div>
        </div>
      </div>
   </div>
</template>
<script>
import SearchTextInput from '@/View/components/searchTextInput.vue'
var moment = require('moment')
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import deboucneMixin from '@/mixins/debounce.js'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
export default {
  name: "lead",
  components: {
    StatusBtn,
    NoRecords,
    SearchTextInput,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      entStatusList: [
        {
          vistStatusId: 1,
          displayText: 'Open',
          id: 'open',
          selected: true,
        },
        {
          vistStatusId: 2,
          displayText: 'Archived',
          id: 'archived',
          selected: false,
        },
      ],
      empStatusList: [],
      searchForJob: '',
      visitLIst: [],
      searchForQuotation: '',
      getJobDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalJobCount: 0,
      filterObject: {
        start: 0,
        length: 500,
        isActive: true,
        sortBy: 'nextVisitDateTime',
        sortOrder: 'DESC',
        entStatusList: 'open',
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
    };
  },
  created() {},
  mounted() {
     this.getJobDebounce = this.debounce(function () {
      this.resetPaginationValues = true
      this.getVisitList()
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getVisitList()
      }
      this.addLead = false
    });
    this.getVisitList()
  },
  beforeDestroy() {},
  methods: {
    closePopup (data, value) {
      this.$root.$emit('closeScheduleJobSel', data, value)
    },
    // showJobDetail (id) {
    //   this.$router.push({name: 'jobDetail', params: {jobId: id}})
    // },
    toggleActiveAndInactiveA (id, dataVal) {
      this.entStatusList.map((data, i) => {
          if (dataVal.id === data.id) {
            if (this.entStatusList[i].selected) {
               this.filterObject.entStatusList = []
               this.entStatusList[i].selected = false
            } else {
                this.filterObject.entStatusList = data.id
               this.entStatusList[i].selected = true
            }
          } else {
            this.entStatusList[i].selected = false
          }
      })
      this.resetPaginationValues = true
      this.getVisitList()
    },
    searchCall (data) {
      this.searchForJob = data
      this.getJobDebounce()
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getVisitList(true)
    },
    getJobCount (data) {
      this.totalJobCount = data
    },
    getVisitList (showlistLoader) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getJobList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForJob,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.entStatusList,
        0,
        false,
        '',
        '',
        response => {
          this.totalJobCount = response.Data.count
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < temp.length; index++) {
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            currentDate = new Date(currentDate);
            const cFormated = moment(currentDate).format('YYYY-MM-DD');
            if ( moment(new Date(temp[index].nextVisitDateTime)).format('YYYY-MM-DD',) === cFormated) {
              temp[index].dateStatus = 'Today';
            } else if ( new Date(temp[index].nextVisitDateTime).getTime() < currentDate.getTime()) {
              temp[index].dateStatus = 'Late';
            } else {
              temp[index].dateStatus = 'Upcoming';
            }
          }
          this.visitLIst = temp
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  }
};
</script>
<style scoped>
</style>